$color_1: #fff;
$color_2: #000;
$color_3: rgba(0, 0, 0, 0.45);
$color_4: #7d8488;
$font-family_1: "Roboto", sans-serif;
$border-color_1: transparent #fff transparent transparent;
$border-color_2: transparent transparent transparent #e1ffc7;
$border-color_3: transparent transparent transparent #fff;

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,300');
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.1.2/css/material-design-iconic-font.min.css');

/* Status Bar */
/* Chat */
/* User Bar */
/* Conversation */
/* Messages */
/* Compose */
/* Small Screens */
* {
  box-sizing: inherit;

  &:before {
    box-sizing: inherit;
  }

  &:after {
    box-sizing: inherit;
  }
}

.screen-container {
  height: 100%;
}

.status-bar {
  height: 25px;
  background: #004e45;
  color: $color_1;
  font-size: 14px;
  padding: 0.2rem 22px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  div {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 8px;
    font-weight: 600;
  }

  #copy-bot-id {
    background: #005e54;
    border: none;
    color: white;
    border-radius: 0.2em;
    cursor: pointer;
  }
}

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

.user-bar {
  height: 55px;
  background: #005e54;
  color: $color_1;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  div {
    float: left;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
  }

  .actions {
    float: right;
    margin: 0 0 0 20px;

    &.more {
      margin: 0 12px 0 32px;
    }

    &.attachment {
      margin: 0 0 0 30px;

      i {
        display: block;
        transform: rotate(-45deg);
      }
    }
  }

  .avatar {
    margin: 0 0 0 5px;
    width: 36px;
    height: 36px;

    img {
      border-radius: 50%;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
      display: block;
      width: 100%;
    }
  }

  .name {
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 150px;
  }

  .status {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 1;
  // background: none;

  &::-webkit-scrollbar {
    transition: all 0.5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }

  .conversation-container {
    height: calc(100% - 68px);
    box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

.message {
  color: $color_2;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  // z-index: -1;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:first-child {
    margin: 16px 0 8px;
  }

  .title {
    margin-bottom: 5px;
  }

  .link {
    margin-bottom: 5px;
  }
}

.metadata {
  // display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;

  .time {
    color: $color_3;
    font-size: 11px;
    display: inline-block;
  }

  .tick {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    top: 4px;
    height: 16px;
    width: 16px;

    svg {
      position: absolute;
      transition: 0.5s ease-in-out;

      &:first-child {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: perspective(800px) rotateY(180deg);
        transform: perspective(800px) rotateY(180deg);
      }

      &:last-child {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: perspective(800px) rotateY(0deg);
        transform: perspective(800px) rotateY(0deg);
      }
    }
  }

  .tick-animation {
    svg {
      &:first-child {
        -webkit-transform: perspective(800px) rotateY(0);
        transform: perspective(800px) rotateY(0);
      }

      &:last-child {
        -webkit-transform: perspective(800px) rotateY(-179.9deg);
        transform: perspective(800px) rotateY(-179.9deg);
      }
    }
  }
}

.message {
  &.received {
    background: #fff;
    border-radius: 0px 5px 5px 5px;
    float: left;

    .metadata {
      padding: 0 0 0 16px;
    }

    &:after {
      border-width: 0px 10px 10px 0;
      border-color: $border-color_1;
      top: 0;
      left: -10px;
    }
  }

  &.sent {
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    float: right;

    &:after {
      border-width: 0px 0 10px 10px;
      border-color: $border-color_2;
      top: 0;
      right: -10px;
    }
  }

  .collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    i {
      font-size: 22px;
      margin: 0 3px;
      color: #565656;
    }
  }

  .list-content {
    p {
      margin-bottom: 0;
    }

    ul {
      margin-top: 0;

      li {
        list-style-type: circle;
      }
    }
  }

  .footer-cta {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(17, 27, 33, 0.06);
    padding: 9px 0 0;
    align-items: center;
    color: #027eb5;
    cursor: pointer;

    i {
      margin: 0 5px;
      font-size: 2rem;
    }
  }

  &.cta-lg {
    border-radius: 5px !important;
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    float: none;
    color: #027eb5;
    cursor: pointer;

    &.back {
      color: #565656;
    }

    &::after {
      display: none;
    }
  }
}

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
  position: relative;

  div {
    background: #fff;
    height: 100%;
  }

  input {
    background: #fff;
    height: 100%;
    color: $color_2;
  }

  .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px 0 0 5px;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 48px;
  }

  .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
    // color: #ffffff;
    // background: #008a7c;
    // margin: 20px 0;
    // height: 50px;
    // padding-left: 22px;
    // font-weight: bold;
  }

  .photo {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
    width: 48px;

    &:after {
      border-width: 0px 0 10px 10px;
      border-color: $border-color_3;
      border-style: solid;
      position: absolute;
      width: 0;
      height: 0;
      content: "";
      top: 0;
      right: -10px;
    }

    i {
      display: block;
      color: $color_4;
      font-size: 24px;
      transform: translate(-50%, -50%);
      position: relative;
      top: 50%;
      left: 50%;
    }
  }

  .send {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0;
    position: relative;
    outline: none;
    // top: 5px;
    // right: 0;
    // box-shadow: 0px 2px 4px #225752;
    // border-radius: 100%;

    .circle {
      background: #008a7c;
      border-radius: 50%;
      color: $color_1;
      position: relative;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
        margin-left: 5px;
      }
    }
  }
}

.choose-one-input {
  // border: '2px solid lightgray !important';
  width: 100%;
  // width: 90%;
  // margin: 1rem;
  margin: 0 auto;
  position: relative;
  height: 100px;
  // border: none !important;
  border: none !important;

  .input-msg {
    background-color: #008069;
    color: #ffffff;
    height: 50px;
    padding-left: 24px;
    font-weight: bold;
  }

  .send {
    position: absolute;
    right: 12px;
    top: 25px;
    box-shadow: 1px 2px 8px #13473e;
    border-radius: 100%;
  }

}

.options-ul {
  max-height: 27rem;
  overflow: scroll;
}

input[type="text"],
textarea {

  background-color: 'red';

}

.list-popup {
  background: #fff;
  position: absolute;
  /* top: 0; */
  width: 100%;
  // height: calc(100% - 100px);
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 999;

  .popup-header {
    background: #008069;
    color: #fff;
    padding: 18px 24px;
    display: flex;
    font-size: 18px;

    i {
      margin-right: 16px;
      cursor: pointer;
    }
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 14px;

      // &:first-child {
      //   color: #008069;
      //   &:hover, &:active{
      //     background-color: #fff;
      //   }
      // }
      // background: #f2f2f2;
      &:hover,
      &:active {
        background-color: #f2f2f2;
      }

      .radio-btn {
        cursor: pointer;
        border: 0px;
        width: 18px;
        height: 2em;
      }
    }
  }
}

// Lightbox Thumbnail 
.thumbnail-delete-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: #d9534f;
  border: none;
  border-radius: 50%;
  padding: 0.5em 0.5em 0.35em;
  cursor: pointer;
}

.upload-more-images-btn label {
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

@media (max-width: 768px) {

  .marvel-device {
    >.screen {
      .chat {
        visibility: visible;
      }
    }

    visibility: hidden;

    .status-bar {
      display: none;
    }
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);

    .conversation-container {
      height: calc(100% - 150px);
      // height: calc(100vh - 120px);
      //height: calc(100vh - 290px);
    }
  }

  .options-ul {
    height: calc(100vh - 290px);
  }
}