$color_1: #fff;
$color_2: #000;
$color_3: rgba(0, 0, 0, 0.45);
$color_4: #7d8488;
$font-family_1: "Roboto", sans-serif;
$border-color_1: transparent #fff transparent transparent;
$border-color_2: transparent transparent transparent #e1ffc7;
$border-color_3: transparent transparent transparent #fff;

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,300');
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.1.2/css/material-design-iconic-font.min.css');

/* Status Bar */
/* Chat */
/* User Bar */
/* Conversation */
/* Messages */
/* Compose */
/* Small Screens */
* {
  box-sizing: inherit;

  &:before {
    box-sizing: inherit;
  }

  &:after {
    box-sizing: inherit;
  }
}

.screen-container {
  height: 100%;
}

.helper-text-login{
  font-size: 14px;
  text-align: left;
  margin-top: -1rem;
}

label{
  float: left;
  font-size: 14px;
  margin-bottom: 6px;
}

.radio-div {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  input[type="radio"] {
    width: 16px;
    height: 14px;
  }
  .radio-label {
    padding-left: 4px;
    text-transform: uppercase;
  }
}

.status-bar {
  height: 25px;
  background: #004e45;
  color: $color_1;
  font-size: 14px;
  padding: 0 8px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  div {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 8px;
    font-weight: 600;
  }
}

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.card {
  justify-content: center;
  display: flex;
  /* align-content: center; */
  align-items: center;
  height: 100%;
  flex-direction: column;
  background: #ffffff;

  .img-container {
    margin-bottom: 18px;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
    }
  }

  form {
    width: 80%;

    .cust-input {
      height: 40px;
      border-radius: 8px;
      padding-left: 12px;
      border: 1px solid #f1f1f1;
      width: calc(100% - 12px);
      margin-bottom: 18px;

      &:focus,
      &:active,
      &:focus-visible {
        border: 1px solid #008a7c;
      }
    }

    .login-btn {
      height: 40px;
      border-radius: 8px;
      width: 100%;
      background: #008a7c;
      border: 1px solid #008a7c;
      color: #fff;
      margin-top: 18px;
      margin-bottom: 18px;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #fff;
        color: #008a7c;

      }
    }

    .disabled-button {
      background-color: lightgray;
      color: black;
      color: #ffffff;
      border-color: lightgray;
      pointer-events: none;
      cursor: not-allowed;
    }

    .container {
      text-align: right;
      width: 100%;
      height: 404px;

      .animate-div {
        -webkit-animation: fadeIn 0.5s linear forwards;
        animation: fadeIn 0.5s linear forwards;
        opacity: 0;
      }
      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

      .psw {
        font-size: 13px;

        a {
          &:hover {
            color: #008a7c;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .marvel-device {
    >.screen {
      .chat {
        visibility: visible;
      }
    }

    visibility: hidden;

    .status-bar {
      display: none;
    }
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);

    .conversation-container {
      height: calc(100vh - 120px);
    }
  }
}