.message-text {
    display: flex;

    p {
        width: 250px;
    }
}

.img-arr-container {
    width: 100%;
}

.edit-icon {
    width: 2rem;
    height: 2.3rem;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #4285f4;
    color: #fff;
    display: grid;
    place-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
}

.edit-icon:hover {
    background-color: #3c78d8;
}

.img-container {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    img {
        max-width: 7rem;
        cursor: pointer;
    }
}

.image-edit-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

#message-edit-form {
    width: 100%;
    padding-right: 0.5em;
    display: flex;
    flex-direction: column;
}

.quill {
    height: 20rem;
    max-height: 20rem;
    border: none;
    overflow: hidden;
    padding-bottom: 5rem;
}

#message-edit-form-btn-grp {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    margin: 0.5em 0;
}

#message-edit-form-btn-grp button {
    border: none;
    font-size: 1.1rem;
    padding: 0.25em 0.5em;
    font-weight: bold;
    border-radius: 0.25em;
    color: white;
    cursor: pointer;
}

#message-edit-form-submit {
    background: #008069;
}

#message-edit-form-cancel {
    background: #d9534f;
}

.upload-files-btn {
    border: none;
    background: none;

    label,
    >div {
        background: #4285f4;
        border: none;
        border-radius: 0.5em;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
        cursor: pointer;
    }
}

.delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: #d9534f;
    border: none;
    border-radius: 50%;
    padding: 0.5em 0.5em 0.35em;
    cursor: pointer;
    z-index: 1;
}