
$font-family_1: "Roboto", sans-serif;
html {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family_1;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #root{
    width: 100% !important;
    height: 100% !important;
  }
  .page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
  }

  .marvel-device {
    display: inline-block;
    position: relative;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
  
    .screen {
      text-align: left;
      width: 100%;
      position: relative;
      height: 100%;
      z-index: 3;
      background: white;
      overflow: hidden;
      display: block;
      border-radius: 1px;
      -webkit-box-shadow: 0 0 0 3px #111;
      box-shadow: 0 0 0 3px #111;
    }
  
  
    &.iphone8 {
      width: 375px;
      height: 667px;
      padding: 105px 24px;
      background: #d9dbdc;
      border-radius: 56px;
      background: #464646;
      -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);
  
      &:before {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        position: absolute;
        top: 6px;
        content: '';
        left: 6px;
        border-radius: 50px;
        background: #080808;
        z-index: 1;
      }
  
      .top-bar {
        height: 14px;
        position: absolute;
        top: 68px;
        left: 0;
        background: #212121;
      }
  
      .sleep {
        position: absolute;
        top: 190px;
        right: -4px;
        width: 4px;
        height: 66px;
        border-radius: 0px 2px 2px 0px;
        background: #464646;
      }
  
      .volume {
        position: absolute;
        left: -4px;
        top: 188px;
        z-index: 0;
        height: 66px;
        width: 4px;
        border-radius: 2px 0px 0px 2px;
        background: #464646;
  
        &:before {
          position: absolute;
          left: 2px;
          top: -78px;
          height: 40px;
          width: 2px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }
  
        &:after {
          position: absolute;
          left: 0px;
          top: 82px;
          height: 66px;
          width: 4px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }
  
      }
  
      .camera {
        background: #3c3d3d;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 50%;
        margin-left: -6px;
        border-radius: 100%;
        z-index: 3;
      }
  
      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;
  
      }
    }

    &.nexus5 {
      padding: 50px 15px 50px 15px;
      width: 320px;
      height: 568px;
      background: #1e1e1e;
      border-radius: 20px;
  
      &:before {
        border-radius: 600px / 50px;
        background: inherit;
        content: '';
        top: 0;
        position: absolute;
        height: 103.1%;
        width: calc(100% - 26px);
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
  
      .top-bar {
        width: calc(100% - 8px);
        height: calc(100% - 6px);
        position: absolute;
        top: 3px;
        left: 4px;
        border-radius: 20px;
        background: #181818;
  
        &:before {
          border-radius: 600px / 50px;
          background: inherit;
          content: '';
          top: 0;
          position: absolute;
          height: 103.0%;
          width: calc(100% - 26px);
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
  
      .bottom-bar {
        display: none;
      }
  
      .sleep {
        width: 3px;
        position: absolute;
        left: -3px;
        top: 110px;
        height: 100px;
        background: inherit;
        border-radius: 2px 0px 0px 2px;
      }
  
      .volume {
        width: 3px;
        position: absolute;
        right: -3px;
        top: 70px;
        height: 45px;
        background: inherit;
        border-radius: 0px 2px 2px 0px;
      }
  
      .camera {
        background: #3c3d3d;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 18px;
        left: 50%;
        z-index: 3;
        margin-left: -5px;
        border-radius: 100%;
  
        &:before {
          background: #3c3d3d;
          width: 6px;
          height: 6px;
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: -100px;
          z-index: 3;
          border-radius: 100%;
        }
      }
  
      &.landscape {
        padding: 15px 50px 15px 50px;
        height: 320px;
        width: 568px;
  
        &:before {
          width: 103.1%;
          height: calc(100% - 26px);
          border-radius: 50px / 600px;
        }
  
        .top-bar {
          left: 3px;
          top: 4px;
          height: calc(100% - 8px);
          width: calc(100% - 6px);
  
          &:before {
            width: 103%;
            height: calc(100% - 26px);
            border-radius: 50px / 600px;
          }
        }
  
        .sleep {
          height: 3px;
          width: 100px;
          left: calc(100% - 210px);
          top: -3px;
          border-radius: 2px 2px 0px 0px;
        }
  
        .volume {
          height: 3px;
          width: 45px;
          right: 70px;
          top: 100%;
          border-radius: 0px 0px 2px 2px;
        }
  
        .camera {
          top: 50%;
          left: calc(100% - 18px);
          margin-left: 0;
          margin-top: -5px;
  
          &:before {
            top: -100px;
            left: 2px;
          }
        }
      }
  
      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;
  
      }
    }
  
    &.iphone-x {
      width: 375px;
      // height: 812px;
      height: 700px;
      padding: 26px;
      background: #fdfdfd;
      box-shadow: inset 0 0 11px 0 black;
      border-radius: 66px;

      .overflow {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 66px;
        overflow: hidden;
      }

      .shadow {
        border-radius: 100%;
        width: 90px;
        height: 90px;
        position: absolute;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 60%);
      }

      .shadow--tl {
        top: -20px;
        left: -20px;
      }

      .shadow--tr {
        top: -20px;
        right: -20px;
      }

      .shadow--bl {
        bottom: -20px;
        left: -20px;
      }

      .shadow--br {
        bottom: -20px;
        right: -20px;
      }

      &:before {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        top: 5px;
        content: '';
        left: 5px;
        border-radius: 61px;
        background: black;
        z-index: 1;
      }

      .inner-shadow {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        position: absolute;
        top: 10px;
        overflow: hidden;
        left: 10px;
        border-radius: 56px;
        box-shadow: inset 0 0 15px 0 rgba(white, 0.66);
        z-index: 1;

        &:before {
          box-shadow: inset 0 0 20px 0 #FFFFFF;
          width: 100%;
          height: 116%;
          position: absolute;
          top: -8%;
          content: '';
          left: 0;
          border-radius: 200px / 112px;
          z-index: 2;
        }
      }

      .screen {
        border-radius: 40px;
        box-shadow: none;
        @media (max-width: 768px) {
          border-radius: 0;
        }
      }

      .top-bar,
      .bottom-bar {
        width: 100%;
        position: absolute;
        height: 8px;
        background: rgba(black, 0.1);
        left: 0;
      }

      .top-bar {
        top: 80px;
      }

      .bottom-bar {
        bottom: 80px;
      }

      .volume,
      .volume:before,
      .volume:after,
      .sleep {
        width: 3px;
        background: #b5b5b5;
        position: absolute;
      }

      .volume {
        left: -3px;
        top: 116px;
        height: 32px;

        &:before {
          height: 62px;
          top: 62px;
          content: '';
          left: 0;
        }

        &:after {
          height: 62px;
          top: 140px;
          content: '';
          left: 0;
        }
      }

      .sleep {
        height: 96px;
        top: 200px;
        right: -3px;
      }

      .camera {
        width: 6px;
        height: 6px;
        top: 9px;
        border-radius: 100%;
        position: absolute;
        left: 154px;
        background: #0d4d71;
      }

      .speaker {
        height: 6px;
        width: 60px;
        left: 50%;
        position: absolute;
        top: 9px;
        margin-left: -30px;
        background: #171818;
        border-radius: 6px;
      }

      .notch {
        position: absolute;
        width: 210px;
        height: 30px;
        top: 26px;
        left: 108px;
        z-index: 4;
        background: black;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        &:before,
        &:after {
          content: '';
          height: 8px;
          position: absolute;
          top: 0;
          width: 8px;
        }

        &:after {
          background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%);
          left: -8px;
        }

        &:before {
          background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
          right: -8px;
        }
      }

      &.landscape {
        height: 375px;
        width: 812px;

        .top-bar,
        .bottom-bar {
          width: 8px;
          height: 100%;
          top: 0;
        }

        .top-bar {
          left: 80px;
        }

        .bottom-bar {
          right: 80px;
          bottom: auto;
          left: auto;
        }

        .volume,
        .volume:before,
        .volume:after,
        .sleep {
          height: 3px;
        }

        .inner-shadow:before {
          height: 100%;
          width: 116%;
          left: -8%;
          top: 0;
          border-radius: 112px / 200px;
        }

        .volume {
          bottom: -3px;
          top: auto;
          left: 116px;
          width: 32px;

          &:before {
            width: 62px;
            left: 62px;
            top: 0;
          }

          &:after {
            width: 62px;
            left: 140px;
            top: 0;
          }
        }

        .sleep {
          width: 96px;
          left: 200px;
          top: -3px;
          right: auto;
        }

        .camera {
          left: 9px;
          bottom: 154px;
          top: auto;
        }

        .speaker {
          width: 6px;
          height: 60px;
          left: 9px;
          top: 50%;
          margin-top: -30px;
          margin-left: 0;
        }

        .notch {
          height: 210px;
          width: 30px;
          left: 26px;
          bottom: 108px;
          top: auto;
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
          border-bottom-left-radius: 0;

          &:before,
          &:after {
            left: 0;
          }

          &:after {
            background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
            bottom: -8px;
            top: auto;
          }

          &:before {
            background: radial-gradient(circle at top right, transparent 0, transparent 70%, black 70%, black 100%);
            top: -8px;
          }
        }
      }

      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;

      }
    }

    &.iphone5s,
    &.iphone5c {
      padding: 105px 22px;
      background: #2c2b2c;
      width: 320px;
      height: 568px;
      border-radius: 50px;

      &:before {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        top: 4px;
        content: '';
        left: 4px;
        border-radius: 46px;
        background: #1e1e1e;
        z-index: 1;
      }

      .sleep {
        position: absolute;
        top: -4px;
        right: 60px;
        width: 60px;
        height: 4px;
        border-radius: 2px 2px 0px 0px;
        background: #282727;
      }

      .volume {
        position: absolute;
        left: -4px;
        top: 180px;
        z-index: 0;
        height: 27px;
        width: 4px;
        border-radius: 2px 0px 0px 2px;
        background: #282727;

        &:before {
          position: absolute;
          left: 0px;
          top: -75px;
          height: 35px;
          width: 4px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }

        &:after {
          position: absolute;
          left: 0px;
          bottom: -64px;
          height: 27px;
          width: 4px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }
      }

      .camera {
        background: #3c3d3d;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 32px;
        left: 50%;
        margin-left: -5px;
        border-radius: 5px;
        z-index: 3;
      }

      .sensor {
        background: #3c3d3d;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 60px;
        left: 160px;
        z-index: 3;
        margin-left: -32px;
        border-radius: 5px;
      }

      .speaker {
        background: #292728;
        width: 64px;
        height: 10px;
        position: absolute;
        top: 60px;
        left: 50%;
        margin-left: -32px;
        border-radius: 5px;
        z-index: 3;
      }

      &.landscape {
        padding: 22px 105px;
        height: 320px;
        width: 568px;

        .sleep {
          right: -4px;
          top: calc(100% - 120px);
          height: 60px;
          width: 4px;
          border-radius: 0px 2px 2px 0px;
        }

        .volume {
          width: 27px;
          height: 4px;
          top: -4px;
          left: calc(100% - 180px);
          border-radius: 2px 2px 0px 0px;

          &:before {
            width: 35px;
            height: 4px;
            top: 0px;
            right: -75px;
            left: auto;
            border-radius: 2px 2px 0px 0px;
          }

          &:after {
            bottom: 0px;
            left: -64px;
            z-index: 999;
            height: 4px;
            width: 27px;
            border-radius: 2px 2px 0px 0px;
          }
        }

        .sensor {
          top: 160px;
          left: calc(100% - 60px);
          margin-left: 0px;
          margin-top: -32px;
        }

        .speaker {
          height: 64px;
          width: 10px;
          left: calc(100% - 60px);
          top: 50%;
          margin-left: 0px;
          margin-top: -32px;
        }

        .camera {
          left: calc(100% - 32px);
          top: 50%;
          margin-left: 0px;
          margin-top: -5px;
        }
      }

      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;

      }
    }

    &.iphone5s {
      .home {
        border-radius: 36px;
        width: 68px;
        box-shadow: inset 0 0 0 4px #2c2b2c;
        height: 68px;
        position: absolute;
        left: 50%;
        margin-left: -34px;
        bottom: 19px;
        z-index: 3;
      }

      .top-bar {
        top: 70px;
        position: absolute;
        left: 0;
      }

      .bottom-bar {
        bottom: 70px;
        position: absolute;
        left: 0;
      }

      &.landscape {
        .home {
          left: 19px;
          bottom: 50%;
          margin-bottom: -34px;
          margin-left: 0px;
        }

        .top-bar {
          left: 70px;
          top: 0px;
          width: 3px;
          height: 100%;
        }

        .bottom-bar {
          right: 70px;
          left: auto;
          bottom: 0px;
          width: 3px;
          height: 100%;
        }
      }

      &.silver {
        background: #bcbcbc;

        &:before {
          background: #fcfcfc;
        }

        .volume,
        .sleep {
          background: #d6d6d6;
        }

        .top-bar,
        .bottom-bar {
          background: #eaebec;
        }

        .home {
          box-shadow: inset 0 0 0 4px #bcbcbc;
        }
      }

      &.gold {
        background: #f9e7d3;

        &:before {
          background: #fcfcfc;
        }

        .volume,
        .sleep {
          background: #f9e7d3;
        }

        .top-bar,
        .bottom-bar {
          background: white;
        }

        .home {
          box-shadow: inset 0 0 0 4px #f9e7d3;
        }
      }

      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;

      }
    }

    &.iphone5c {
      background: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

      .top-bar,
      .bottom-bar {
        display: none;
      }

      .home {
        background: #242324;
        border-radius: 36px;
        width: 68px;
        height: 68px;
        z-index: 3;
        position: absolute;
        left: 50%;
        margin-left: -34px;
        bottom: 19px;

        &:after {
          width: 20px;
          height: 20px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          position: absolute;
          display: block;
          content: '';
          top: 50%;
          left: 50%;
          margin-top: -11px;
          margin-left: -11px;
        }
      }

      &.landscape {
        .home {
          left: 19px;
          bottom: 50%;
          margin-bottom: -34px;
          margin-left: 0px;
        }
      }

      .volume,
      .sleep {
        background: #dddddd;
      }

      &.red {
        background: #f96b6c;

        .volume,
        .sleep {
          background: #ed5758;
        }
      }

      &.yellow {
        background: #f2dc60;

        .volume,
        .sleep {
          background: #e5ce4c;
        }
      }

      &.green {
        background: #97e563;

        .volume,
        .sleep {
          background: #85d94d;
        }
      }

      &.blue {
        background: #33a2db;

        .volume,
        .sleep {
          background: #2694cd;
        }
      }

      @media (max-width: 768px) {
        border-radius: 0;
        flex: none;
        padding: 0;
        max-width: none;
        overflow: hidden;
        height: 100%;
        width: 100%;

      }
    }
  
  }