.pdfCard {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
}

.pdfPreview {
    position: relative;
    height: 8rem;
    width: 100%;
}

.pdfPreview img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.pdfDetails {
    background: #f1f1f1;
    padding: 0.5em;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.pdfDetailGroup {
    gap: 0.5rem;
    display: flex;
}

.pdfName {
    /* color: white; */
    margin-bottom: 0.5rem;
}

.pdfIcon {
    width: 2rem;
    height: auto;
}

.pdfIcon img {
    object-fit: cover;
    height: 2rem;
    width: 1.625rem;
}

.pdfMetadata {
    display: flex;
    gap: 0.25em;
    color: #666;
    font-size: 0.9rem;
}

.download {
    border: none;
    background: none;
    cursor: pointer;
}

.delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: #d9534f;
    border: none;
    border-radius: 50%;
    padding: 0.5em 0.5em 0.35em;
    cursor: pointer;
    z-index: 1;
}